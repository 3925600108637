<template>
  <div class="home">
    <div class="header">
      <div class="logo"><img src="../../assets/img/logo1.jpg" alt=""></div>
      <div class="register" @click="openDialog">registo</div>
    </div>
    <div class="banner"></div>
    <div class="allGames">
      <div class="game1">
        <div class="title1">
          <div class="title">Jogos de Lazer</div>
          <div class="more" @click="openDialog">mais</div>
        </div>
        <div class="games">
          <div @click="openDialog">
            <img src="../../assets/img/game1.webp" alt="">
            <div class="name">2048 Balls</div>
          </div>
          <div @click="openDialog">
            <img src="../../assets/img/game2.webp" alt="">
            <div class="name">Blumgi-Ball-Game</div>
          </div>
          <div @click="openDialog">
            <img src="../../assets/img/game3.webp" alt="">
            <div class="name">Dino Run</div>
          </div>
          <div @click="openDialog">
            <img src="../../assets/img/game4.webp" alt="">
            <div class="name">Fruit Ninja Online</div>
          </div>
          <div @click="openDialog">
            <img src="../../assets/img/game5.webp" alt="">
            <div class="name">Goalkeeper Challenge</div>
          </div>
          <div @click="openDialog">
            <img src="../../assets/img/game6.webp" alt="">
            <div class="name">Halloween-Craft</div>
          </div>
        </div>
      </div>
      <div class="game1">
        <div class="title1">
          <div class="title">Jogos de Acção</div>
          <div class="more" @click="openDialog">mais</div>
        </div>
        <div class="games">
          <div @click="openDialog">
            <img src="../../assets/img/game7.webp" alt="">
            <div class="name">HexAquatic-Kraken</div>
          </div>
          <div @click="openDialog">
            <img src="../../assets/img/game8.webp" alt="">
            <div class="name">Jelly-Merge</div>
          </div>
          <div @click="openDialog">
            <img src="../../assets/img/game9.webp" alt="">
            <div class="name">Jewels Blitz 2</div>  
          </div>
          <div @click="openDialog">
            <img src="../../assets/img/game10.webp" alt="">
            <div class="name">LightItUp</div>  
          </div>
          <div @click="openDialog">
            <img src="../../assets/img/game11.webp" alt="">
            <div class="name">Neon-Racer</div>
          </div>
          <div @click="openDialog">
            <img src="../../assets/img/game12.webp" alt="">
            <div class="name">oddbods</div>
          </div>
        </div>
      </div>
      <div class="game1">
        <div class="title1">
          <div class="title">Jogo de puzzles</div>
          <div class="more" @click="openDialog">mais</div>
        </div>
        <div class="games">
          <div @click="openDialog">
            <img src="../../assets/img/game13.webp" alt="">
            <div class="name">Pool Mania</div>
          </div>
          <div @click="openDialog">
            <img src="../../assets/img/game14.webp" alt="">
            <div class="name">Princess Subway Surfers Runner</div>
          </div>
          <div @click="openDialog">
            <img src="../../assets/img/game15.webp" alt="">
            <div class="name">Strike-Ultimate-Bowling</div>  
          </div>
          <div @click="openDialog">
            <img src="../../assets/img/game16.webp" alt="">
            <div class="name">Subway Surfer Monaco</div>
          </div>
          <div @click="openDialog">
            <img src="../../assets/img/game17.webp" alt="">
            <div class="name">Subway-Rabbit</div>
          </div>
          <div @click="openDialog">
            <img src="../../assets/img/game18.webp" alt="">
            <div class="name">Woodventure</div>
          </div>
        </div>
      </div>
    </div>
    <div class="footer">@Copyright 2002-2023</div>

    <el-dialog
      v-model="dialogVisible"
      width="300px"
      :before-close="handleClose"
      class="registerModal"
      align-center
    >
      <el-form :model="form">
        <el-form-item label="Conta：">
          <el-input v-model="form.name" placeholder="Por favor insira uma conta" maxlength='10' />
        </el-form-item>
        <el-form-item label="Senha：">
          <el-input type="password" placeholder="Introduza a senha" v-model="form.password" />
        </el-form-item>
        <el-form-item>
          <el-button type="primary" class="zhuce" @click="onSubmit">registo</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>


  </div>
</template>

<script>

import { ref, reactive } from 'vue'
import { ElMessage } from 'element-plus'

export default {
  name: 'Home',
  setup() {
    const dialogVisible = ref(false)
    const form = reactive({
      name: '',
      password: ''
    })

    const onSubmit = () => {
      ElMessage({
        message: 'erro de rede.',
        type: 'warning',
      })
    }

    const openDialog = () => {
      dialogVisible.value = true
    }

    const handleClose = () => {
      dialogVisible.value = false
    }
    return { dialogVisible, form, handleClose, openDialog, onSubmit }
  }
}
</script>

<style lang="scss" scoped>
@import url('./index.scss');
</style>