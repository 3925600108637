import { createRouter,createWebHashHistory } from "vue-router";
import Home from '../view/index/index.vue';
// import Other from '../view/other/index.vue';

const routes = [
  {
    path:'/',
    name: 'home',
    component:Home
  }
]

const router = createRouter({
  history:createWebHashHistory(),
  routes
})

export default router